<template>
	<div class="block-inner">
		<div v-if="isFailed">
			<h2>Upload failed.</h2>
			<pre>{{ uploadError }}</pre>
		</div>

		<DropZone v-else :is-saving="isSaving" :accept-types="acceptTypes" @upload="filesChange" />

		<div v-if="fileUrls.length" class="current-file">
			<div class="audio-file">
				<h2>Current</h2>
				<audio controls :src="fileUrls[0]">
					Your browser does not support the
					<code>audio</code> element.
				</audio>
			</div>

			<div class="transcript">
				<RichEditor v-model="block.meta.transcript" :block-id="block.id" :inline="true" placeholder="Add a transcript..." @input="$emit('edit')" />
			</div>
		</div>
	</div>
</template>

<script>
	import editorUploadMixin from '../../mixins/editorUploadMixin';
	import DropZone from '../DropZone';
	import RichEditor from '../RichEditor';

	export default {
		components: {
			DropZone,
			RichEditor
		},
		mixins: [editorUploadMixin],
		props: {
			block: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				acceptTypes: [
					{
						label: 'Audio',
						code: 'audio',
						mimeTypes: [
							'audio/mpeg',
							'audio/wav'
						]
					}
				]
			};
		},
		mounted() {
			this.reset();
		}
	};
</script>

<style lang="scss" scoped>
	.dropzone::v-deep {
		margin-bottom: $default_padding;
	}

	.current-file {
		display: flex;
		flex-flow: row nowrap;

		.audio-file {
			margin-right: $default_padding;
		}

		audio {
			outline: none;

			&::-webkit-media-controls-panel {
				background-color: $color__white;
			}
		}
	}

	.transcript {
		flex: 1;
	}

	h2 {
		margin: 0 0 1rem;
		font-size: 1.25rem;
	}
</style>